import AccountsIcon from "../../assets/accountIcon.svg";
import "./navbar.scss";
import { logOut } from "../../utils";
import { useNavigate } from "react-router-dom";
import menubar from "../../assets/menubar.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import Loans from "../Loans";
import VouchersNav from "../VouchersNav";
import ToolsNav from "../ToolsNav";
import LogoIcon from "../../components/Logo";
import io from 'socket.io-client';
import { jwtDecode, JwtPayload } from "jwt-decode";
import NotificationDropdown from "../NotificationDropdown";
import env from "../../config/config";
import { Fab, IconButton, Link, Toolbar, useTheme, Menu, MenuItem, Box, Typography } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import AppBar from '@mui/material/AppBar';

// NotificationDropdown
interface menuIdProps {
    menuId?: number
}


// const useKaptureCRM = () => {
//     const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);
//     const scriptRef = useRef<HTMLScriptElement | null>(null);

//     useEffect(() => {
//         const getToken = () => {
//             const storedToken = sessionStorage.getItem('AuthToken');
//             if (storedToken) {
//                 try {
//                     const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
//                     setToken(decodedToken);
//                     console.log("Token set:", decodedToken);
//                 } catch (error) {
//                     console.error("Error decoding token:", error);
//                 }
//             } else {
//                 console.log("No token found in sessionStorage");
//             }
//         };

//         getToken();
//         // Check for token every second in case it's not immediately available
//         const intervalId = setInterval(getToken, 1000);

//         return () => clearInterval(intervalId);
//     }, []);

//     useEffect(() => {
//         const mountScript = () => {
//             if (!scriptRef.current && typeof window !== 'undefined' && token?.ecc) {
//                 console.log("Mounting KaptureCRM script");
//                 const script = document.createElement('script');
//                 script.src = "https://selfserveapp.kapturecrm.com/js/init_chat.js";
//                 script.id = "kap-snippet";
//                 script.setAttribute('data-supportkey', '670c80ec2aa37bf6917dd10e6a271528f11913527193474352');
//                 script.setAttribute('chat-for', 'TICKET');
//                 script.setAttribute('data-server', 'Indian');
//                 script.setAttribute('chat-type', 'RNF');
//                 script.setAttribute('data-customercode', token.ecc);
//                 script.setAttribute('data-iv', 'TIMONALSOEATPOOCHI');
//                 script.type = "text/javascript";
//                 document.body.appendChild(script);
//                 scriptRef.current = script;
//                 console.log("KaptureCRM script mounted");
//             }
//         };

//         if (token?.ecc) {
//             mountScript();
//         }

//         return () => {
//             if (scriptRef.current && typeof window !== 'undefined') {
//                 document.body.removeChild(scriptRef.current);
//                 scriptRef.current = null;
//                 console.log("KaptureCRM script unmounted");
//             }
//         };
//     }, [token]);

//     return { token, scriptRef };
// };




const socket = io(env.API_BASE_URL as string, {
    transports: ["websocket"]
});

const Navbar = ({ menuId }: menuIdProps) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
    const [showSubMenu, setSubMenu] = useState(false);

    const theme = useTheme()

    const [newNotification, setNotification] = useState(false);
    const scriptRef = useRef<HTMLScriptElement | null>(null);

    const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);

    const alreadyLoaded = localStorage.getItem('alreadyLoaded');
    if (!alreadyLoaded) {
        localStorage.setItem('alreadyLoaded', "true");
        window.location.reload();
    }


    useEffect(() => {
        const storedToken = sessionStorage.getItem('AuthToken');
        if (storedToken) {
            try {
                const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
                setToken(decodedToken);
                // console.log("Token set:", decodedToken);
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        } else {
            console.log("No token found in sessionStorage");
        }
    }, []);

    const userSlug = token ? `WL${token.id}` : '';

    const handleNotification = useCallback((data: any) => {
        // console.log("Received notification:", data);
        if (data) {
            setNotification(data.newNotification);
        } else {
            console.error("Invalid notification data:", data);
        }
    }, []);


    // useEffect(() => {
        // const mountScript = () => {
        //     if (token?.ecc && !scriptRef.current) {
        //         const script = document.createElement('script');
        //         script.src = "https://selfserveapp.kapturecrm.com/js/init_chat.js";
        //         script.id = "kap-snippet";
        //         script.setAttribute('data-supportkey', env.KAPTURE_PARTNER_KEY as string);
        //         script.setAttribute('chat-for', 'TICKET');
        //         script.setAttribute('data-server', 'Indian');
        //         script.setAttribute('chat-type', 'RNF');
        //         script.setAttribute('data-customercode', token.ecc);
        //         script.setAttribute('data-iv', env.KAPTURE_IV as string);
        //         script.type = "text/javascript";
        //         document.head.appendChild(script);
        //         scriptRef.current = script;
        //     }
        // };

        // mountScript();

    //     return () => {
    //         if (scriptRef.current) {
    //             const script = document.getElementById('parent_kapchat_container');
    //             if (script && script.parentNode) {
    //                 script.remove()
    //             } else {
    //                 console.warn('KaptureCRM script not found or already removed');
    //             }
    //             scriptRef.current = null;
    //         }

    //         const iframes = document.querySelectorAll('iframe');
    //         iframes.forEach(iframe => {
    //             if (iframe.src.includes('kapturecrm.com')) {
    //                 iframe.remove();
    //             }
    //         });
    //     };
    // }, [token]);


    // const forceRemoveScript = () => {
    //     const script = document.getElementById('parent_kapchat_container');
    //     if (script) {
    //         script.remove()
    //     } else {
    //         console.warn('KaptureCRM script not found or already removed');
    //     }

    //     const iframes = document.querySelectorAll('iframe');
    //     iframes.forEach(iframe => {
    //         if (iframe.src.includes('kapturecrm.com')) {
    //             iframe.remove();
    //         }
    //     });

    //     scriptRef.current = null;
    // };


    useEffect(() => {
        if (userSlug) {
            socket.on(userSlug, handleNotification);
            socket.emit('joinRoom', userSlug);
        }

        return () => {
            if (userSlug) {
                socket.off(userSlug, handleNotification);
            }
        };
    }, [userSlug, handleNotification]);


    useEffect(() => {
        document.addEventListener("mousedown", closeSelector);
    }, [])

    const openSidebar = () => {
        setShowSidebar(true);
    }

    const closeSelector = (e: any): void => {
        const targetElement = e.target as HTMLElement;
        let dropdownBoxClicked = targetElement.closest("#dropdownselect")!;
        if (!dropdownBoxClicked) {
            // setShowMenu(false);
            setSubMenu(false);
        }
    }

    const handleMenu = (menuId: number) => {
        if (menuId === 1) {
            navigate("/profile");
            handleClose();
        } else if (menuId === 2) {
            // forceRemoveScript()
            logOut();
            navigate("/");
            handleClose();
        }

    }

    const handleNav = (id: number) => {
        if (id === 4) {
            navigate("/tools")
        } else if (id === 2) {
            navigate("/loans");
        }

    }

    const handleSubMenu = (e: any) => {
        e.stopPropagation();
        navigate("/loan-history");
    }


    const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setShowMenu(event.currentTarget);
    };

    const handleClose = () => {
        setShowMenu(null);
    };



    const closeSideBar = () => {
        setShowSidebar(false);
    }

    return (
        <AppBar position="static">
            <Toolbar className="header">
                <nav className="navigation-bar">
                    <div className="d-flex align-items-center">
                        <div className="navigation-menu-bar d-none" onClick={() => openSidebar()}>
                            {/* <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            > */}
                                <img src={menubar} alt="menubar" />
                            {/* </IconButton> */}
                        </div>
                        <IconButton href={"/loans"} disableFocusRipple={true} disableTouchRipple={true} sx={{
                            "&:hover": {
                                backgroundColor: "transparent"
                            },
                            flexGrow: 1
                        }}>
                            <LogoIcon/>
                            {/* <img src={LogoIcon} alt="logo" style={{maxWidth:"100%",maxHeight:"100%"}}/> */}
                        </IconButton>
                    </div>
                    <div className="d-flex align-items-center">
                        <ul className={showSidebar ? "nav-side-bar nav-list" : "nav-list"}>
                            <div className="text-end close-icon d-none" onClick={() => closeSideBar()}>x</div>
                            {/* <li>
                            <div className={menuId === 1 ? "nav-item active-menu" : "nav-item"}>
                                <NavDashboard />
                                <div className="ms-2"> Dashboard</div>
                            </div>
                        </li> */}
                            <li >
                                <div className="pos-rel">
                                    <div className={menuId === 2 ? "nav-item active-menu" : "nav-item"} id="dropdownselect">
                                        <Loans />
                                        {/* <img src={LoanIcon} alt="loan-icon" /> */}
                                        <div className="ms-2"
                                            onMouseOver={() => setSubMenu(true)}
                                            onClick={() => handleNav(2)
                                            }
                                        >  Loans</div>

                                        {/* {showSubMenu &&
                                            <div className="dropdown-menu-placement">
                                                <div className="dropdown-item" onClick={(e: any) => handleSubMenu(e)}
                                                    onMouseOut={() => setSubMenu(false)}
                                                >Loan History</div>
                                                <div className="divider"></div>
                                            </div>} */}
                                    </div>

                                </div>

                            </li>
                            {/* <li>
                                <Link
                                    href={env.GIFT_BOX_APP_URL as string}
                                    target="_blank"
                                    sx={{ textDecoration: 'none', color: "inherit" }} >
                                    <div className={menuId === 3 ? "nav-item active-menu" : "nav-item"}>
                                        <VouchersNav />
                                        <div className="ms-2">Vouchers</div>
                                    </div>
                                </Link>
                            </li> */}
                            <li>
                                <div className={menuId === 4 ? "nav-item active-menu" : "nav-item"}>
                                    <ToolsNav />
                                    {/* <img src={ToolsIcon} alt="tools-icon" /> */}
                                    <div className="ms-2" onClick={() => handleNav(4)}>Tools</div>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                            <div className="nav-link">
                                Help Centre
                            </div>
                        </li> */}
                        </ul>
                        <div className="d-flex align-items-center ms-4">
                            <div>
                                <NotificationDropdown
                                    newNotification={newNotification}
                                    socket={socket}
                                />
                                {/* <img src={NotificationIcon} alt="notification-icon" /> */}
                            </div>
                            <div className="pos-rel cursor-pointer ms-3" id="dropdownselect">
                                <img src={AccountsIcon} alt="accounts-icons" onClick={handleProfileMenu} />
                                {/* {showMenu && */}
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={showMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(showMenu)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => handleMenu(1)}>Profile</MenuItem>
                                    <MenuItem onClick={() => handleMenu(2)}>Logout</MenuItem>
                                    {/* <div className="menu-item-overlay">
                                        <div onClick={() => handleMenu(1)} className="cursor-pointer">Profile</div>
                                        <div className="divider"></div>
                                        <div onClick={() => handleMenu(2)} className="cursor-pointer">Logout</div>
                                    </div> */}
                                </Menu>
                                {/* <Dropdown.Menu onSelect={handleMenuChange}>
                                    <Dropdown.Item eventKey="1">Logout</Dropdown.Item>
                                </Dropdown.Menu> */}
                            </div>
                        </div>
                    </div>
                </nav>
                <Fab
                    color="primary"
                    variant="extended"
                    style={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        display: 'flex',
                        zIndex: 1000,
                    }}
                    onClick={() => {
                        const customerCode = token?.ecc.replaceAll("+","%2B") || 'cus_code';
                        const iv = env.KAPTURE_IV || 'cus_iv';

                        const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
                        window.open(url, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <Box sx={{display:'flex',gap:'0.5rem',alignItems:'center',justifyContent:'center'}}>
                    <ChatIcon /> 
                    <Typography textTransform={'capitalize'}>
                        Chat
                    </Typography>
                    </Box>
                </Fab>
            </Toolbar>
        </AppBar>
        // <header className="header">
        //     <nav className="navigation-bar">
        //         <div className="d-flex align-items-center">
        //             <div className="navigation-menu-bar d-none" onClick={() => openSidebar()}>
        //                 <img src={menubar} alt="menubar" />
        //             </div>
        //             <IconButton href={"/loans"} disableFocusRipple={true} disableTouchRipple={true} sx={{
        //                 "&:hover": {
        //                     backgroundColor: "transparent"
        //                 }
        //             }}>
        //                 {/* <LogoIcon/> */}
        //                 <img src={LogoIcon} alt="logo" width={220} height={38} />
        //             </IconButton>
        //         </div>
        //         <div className="d-flex align-items-center">
        //             <ul className={showSidebar ? "nav-side-bar nav-list" : "nav-list"}>
        //                 <div className="text-end close-icon d-none" onClick={() => closeSideBar()}>x</div>
        //                 {/* <li>
        //                     <div className={menuId === 1 ? "nav-item active-menu" : "nav-item"}>
        //                         <NavDashboard />
        //                         <div className="ms-2"> Dashboard</div>
        //                     </div>
        //                 </li> */}
        //                 <li >
        //                     <div className="pos-rel">
        //                         <div className={menuId === 2 ? "nav-item active-menu" : "nav-item"} id="dropdownselect">
        //                             <Loans />
        //                             {/* <img src={LoanIcon} alt="loan-icon" /> */}
        //                             <div className="ms-2"
        //                                 onMouseOver={() => setSubMenu(true)}
        //                                 onClick={() => handleNav(2)
        //                                 }
        //                             >  Loans</div>

        //                             {showSubMenu &&
        //                                 <div className="dropdown-menu-placement">
        //                                     <div className="dropdown-item" onClick={(e: any) => handleSubMenu(e)}
        //                                         onMouseOut={() => setSubMenu(false)}
        //                                     >Loan History</div>
        //                                     <div className="divider"></div>
        //                                 </div>}
        //                         </div>

        //                     </div>

        //                 </li>
        //                 <li>
        //                     <Link
        //                         href={env.GIFT_BOX_APP_URL as string}
        //                         target="_blank"
        //                         sx={{ textDecoration: 'none', color: "inherit" }} >
        //                         <div className={menuId === 3 ? "nav-item active-menu" : "nav-item"}>
        //                             <VouchersNav />
        //                             <div className="ms-2">Vouchers</div>
        //                         </div>
        //                     </Link>
        //                 </li>
        //                 <li>
        //                     <div className={menuId === 4 ? "nav-item active-menu" : "nav-item"}>
        //                         <ToolsNav />
        //                         {/* <img src={ToolsIcon} alt="tools-icon" /> */}
        //                         <div className="ms-2" onClick={() => handleNav(4)}>Tools</div>
        //                     </div>
        //                 </li>
        //                 {/* <li className="nav-item">
        //                     <div className="nav-link">
        //                         Help Centre
        //                     </div>
        //                 </li> */}
        //             </ul>
        //             <div className="d-flex align-items-center ms-4">
        //                 <div>
        //                     <NotificationDropdown
        //                         newNotification={newNotification}
        //                         socket={socket}
        //                     />
        //                     {/* <img src={NotificationIcon} alt="notification-icon" /> */}
        //                 </div>
        //                 <div className="pos-rel cursor-pointer ms-3" id="dropdownselect">
        //                     <img src={AccountsIcon} alt="accounts-icons" onClick={() => setShowMenu(!showMenu)} />
        //                     {showMenu &&
        //                         <div className="menu-item-overlay">
        //                             <div onClick={() => handleMenu(1)} className="cursor-pointer">Profile</div>
        //                             <div className="divider"></div>
        //                             <div onClick={() => handleMenu(2)} className="cursor-pointer">Logout</div>
        //                         </div>}
        //                     {/* <Dropdown.Menu onSelect={handleMenuChange}>
        //                             <Dropdown.Item eventKey="1">Logout</Dropdown.Item>
        //                         </Dropdown.Menu> */}
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>
        //     <Fab
        //         color="primary"
        //         variant="extended"
        //         sx={{
        //             position: 'fixed',
        //             bottom: 16,
        //             right: 16,
        //             display: {
        //                 xs: 'flex',   
        //                 sm: 'flex',  
        //                 md: 'none',    
        //             },
        //             zIndex: 1000,
        //         }}
        //         onClick={() => {
        //             const customerCode = token?.ecc || 'cus_code';
        //             const iv = env.KAPTURE_IV || 'cus_iv';

        //             const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
        //             window.open(url, '_blank', 'noopener,noreferrer');
        //         }}
        //     >
        //         <ChatIcon />
        //     </Fab>
        // </header>
    )
}

export default Navbar;