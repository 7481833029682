import AppButton from "../../components/AppButton";
import React, { useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { REGISTER } from "../../query/Register/registerQuery";
import { VERIFYOTP } from "../../query/Register/otpQuery";
import MobileNumberModal from "./MobileNumberModal";
import OTPModal from "./OTPModal";
import { getToken, getUserDetails, setToken, setUserDetails, validateMobileNumber } from "../../utils";
import "./login.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { StatusIds } from "../../constants";
import LogoIcon from "../../assets/logo.png";
import { sourceMapper } from "../../helpers/constants";


interface verificationDetails {
    mobileNumber: string;
    isChecked: boolean
}

interface res {
    verifyOtp: {
        message: string,
        status: string,
        token: string,
        verifyOtpErrors: [
            { message: string }
        ]
    }
}


declare global {
    interface Window {
        gtag: (...args: any[]) => void;
    }
}


const Login = () => {

    const { state: mobileNumber } = useLocation();

    const [form, setForm] = useState<number>(1);
    const [otp, setOtp] = useState<string[]>(Array(4).fill(""));
    const [verifyLoginDetails, setverifyLoginDetails] = useState<verificationDetails>({
        mobileNumber: "",
        isChecked: false
    });
    const [registration, { data: registerResponse, loading }] = useMutation(REGISTER, {});
    const [verifyMObileOtp, { data: otpResponse, loading: isLoading }] = useMutation(VERIFYOTP, {});
    const [otpRes, setOTPRes] = useState<res | null>(null);
    const [showToast, setShowToast] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        const userDetails = getUserDetails();

        if (mobileNumber) {
            setverifyLoginDetails((Prev) => {
                return {
                    ...Prev,
                    mobileNumber
                }

            })
        }

        if (token && userDetails.userStatusId === 5) {
            navigate("/loans");
        }
    }, []);

    useEffect(() => {
        if (form === 1) {
            if (registerResponse?.register?.status === 201) {
                moveToNextForm();
            }
        } else if (form === 2) {
            if (otpResponse?.verifyOtp?.status === 200) {

                if (window.gtag) {
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-16777039509/5T2NCLWa0uoZEJWd9b8-'
                    });
                }
                
                let userObj = {
                    id: otpResponse?.verifyOtp?.user?.id,
                    userStatusId: otpResponse?.verifyOtp?.user?.userstatusId
                }
                setToken(otpResponse?.verifyOtp?.token);
                setUserDetails(userObj);
                if (+otpResponse?.verifyOtp?.user?.userstatusId === StatusIds.userStatus.newUser) {
                    navigate("/basic-details");
                } else if (+otpResponse?.verifyOtp?.user?.userstatusId >= StatusIds.userStatus.basicDetailsStatus && +otpResponse?.verifyOtp?.user?.userstatusId <= StatusIds.userStatus.permanentAddressStatus) {
                    navigate("/location-details");
                } else if (+otpResponse?.verifyOtp?.user?.userstatusId === StatusIds.userStatus.permanentAddressStatus) {
                    navigate("/employment-details");
                } else {
                    navigate("/loans");
                }
            } else if (otpResponse?.verifyOtp?.status === 400) {
                setOTPRes(otpResponse)
            }
        }
    }, [otpResponse, registerResponse])

    const moveToNextForm = () => {
        setForm((prev) => prev + 1);
    }


    const registerMobileNumber = () => {
        registration({
            variables: {
                input: {
                    "mobileNumber": verifyLoginDetails.mobileNumber
                }
            }
        })
    }

    const verifyOtp = () => {

        verifyMObileOtp({
            variables: {
                input: {
                    "otp": otp.join(""),
                    "mobileNumber": verifyLoginDetails.mobileNumber,
                    "sourceLogin": sourceMapper(),
                }
            }
        })

    }


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'mobileNumber') {
            if (e.target.value.length > 10) return;
            if (/^\d{0,10}$/.test(e.target.value)){
                setverifyLoginDetails({ ...verifyLoginDetails, [e.target.name]: e.target.value })
            }
        } else {
            setverifyLoginDetails({ ...verifyLoginDetails, [e.target.name]: e.target.checked })
        }
    }

    const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (form === 1) {
            registerMobileNumber();
            setOtp(Array(4).fill(""));
            setOTPRes(null);
        } else if (form === 2) {
            verifyOtp();
        }

    };


    const isDisabledButton = () => {
        if (form === 1) {
            if (validateMobileNumber(verifyLoginDetails.mobileNumber) && verifyLoginDetails.isChecked && loading === false) {
                return false
            }
        } else if (otp.join("").length === 4 && isLoading === false) {
            return false;
        }
        return true
    }

    const formView = () => {
        return (
            <form noValidate onSubmit={submitFn}>
                {form === 1 && <MobileNumberModal
                    handleInputChange={handleInputChange}
                    mobileNumber={verifyLoginDetails.mobileNumber}
                    isChecked={verifyLoginDetails.isChecked} />}
                {(form === 2) && <OTPModal
                    data={otpRes}
                    setOtp={setOtp}
                    otp={otp}
                    setForm={setForm}
                    registerMobileNumber={registerMobileNumber}
                    mobileNumber={verifyLoginDetails.mobileNumber} />}
                <div className="mt-80">
                    <AppButton text={form === 1 ? "Get OTP" : "Verify OTP"}
                        className="w-100 app-btn"
                        isDisabled={isDisabledButton()}
                        type="submit"
                        loading={form === 1 ? loading : isLoading} />
                </div>
            </form>
        )
    }


    return (
        <>
            <div className="login-details-view">
                <div className="w-100 app-logo">
                    <img src={LogoIcon} alt="logo" width={220} height={38} onClick={() => navigate("/")} style={{ cursor: "pointer" }} />
                </div>
                <div className="login-details-form-view">
                    {formView()}
                </div>
            </div>
        </>

    )

}

export default Login