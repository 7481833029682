import { TextField, FormControl, Box, Fab, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import PersonIcon from "../../assets/PersonIcon.gif";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "../../components/AppButton";
import ChatIcon from '@mui/icons-material/Chat';
import {
  validateDob,
  validateEmail,
  validateName,
  validatePAN,
} from "../../utils";
import { ValidationErrorMessage } from "../../ValidationError";
import "./userDetails.scss";
import Logo from "../../assets/logo.svg";
import env from "../../config/config";
import ProgressBarComponent from "../../components/ProgressBarComponent";
import { SAVEBASICDETAILS } from "../../query/UserDetails/userDetailsQuery";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import NeedHelpModal from "../../components/NeedHelpModal";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import LogoIcon from '../../components/Logo';
import { jwtDecode, JwtPayload } from "jwt-decode";


let initialBasicInfo = {
  firstName:"",
  middleName:"",
  lastName:"",
  panNumber: "",
  dob: "",
  email: "",
  gender:""
};

const BasicDetails = () => {
  const [basicInfoDetails, setBasicInfoDetails] = useState(initialBasicInfo);
  const [saveBasicDetail, { data: basicDetailsResponse, loading }] =
    useMutation(SAVEBASICDETAILS, {});
  const navigate = useNavigate();
  const [formCompletedStatus, setFormCompletedStatus] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [errors, setErrors] = React.useState();
  const [validation,setValidation] = React.useState<{isValid:boolean,message:string}>({isValid:true,message:""})
  const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('AuthToken');
    if (storedToken) {
        try {
            const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
            setToken(decodedToken);
            // console.log("Token set:", decodedToken);
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    } else {
        console.log("No token found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    if (basicDetailsResponse?.basicDetail?.status === 200 || basicDetailsResponse?.basicDetail?.status === 201) {
      navigate("/location-details");
      setFormCompletedStatus(25);
      setBasicInfoDetails(initialBasicInfo);
    } else {
      setErrors(
        basicDetailsResponse?.basicDetail?.basicDetailErrors[0].message
      );
    }
  }, [basicDetailsResponse,navigate]);

  const savebasicInfo = () => {
    let saveUserInput = {
      email: basicInfoDetails.email,
      firstName: basicInfoDetails.firstName,
      middleName: basicInfoDetails.middleName,
      lastName: basicInfoDetails.lastName,
      panCardNumber: basicInfoDetails.panNumber,
      dateOfBirth: basicInfoDetails.dob,
      gender:basicInfoDetails.gender
    };
    saveBasicDetail({
      variables: {
        input: saveUserInput,
      },
    });
  };

  const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if(basicInfoDetails.firstName === "" || basicInfoDetails.firstName.length > 50){
      return setValidation({isValid:false,message:basicInfoDetails.firstName === "" ? "Please Enter First Name" : "Your first_name should be 50 characters or less"})
    }
    savebasicInfo();
  };

  const handleBasicInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.table({ name, value })
    if (e.target.name === "firstName" || e.target.name === "middleName" || e.target.name === "lastName" ) {
         const pattern = /[!@#$%^&*()\-+={}[\]:;"'<>,?\/~`|\\.0-9]/;
      if (pattern.test(value)) { 
        // console.log("bddd");// not allow special Characters to be entered in input
           return
      }
      setBasicInfoDetails({
        ...basicInfoDetails,
        [name]: value?.trimStart()?.toLowerCase(),
      });
    } else if (e.target.name === "panNumber") {
      if (e.target.value.length > 10) return;
      const pattern = /[!@#$%^&*()\-+={}[\]:;"'<>,?\/~`|\\.]/;
      if (pattern.test(value)) { // not allow special Characters to be entered in input
          return
      }
      setBasicInfoDetails({ ...basicInfoDetails, [name]: value?.trim().toUpperCase() }); // convert value to uppercase
    }else if (e.target.name === "email"){
        
      setBasicInfoDetails(prev => {
        return { ...prev, [name]:value?.trimStart() }
      });
    }
    else {
      setBasicInfoDetails({ ...basicInfoDetails, [name]: value });
    }
  };

  const handleDate = (_date: any) => {
    const formatDate = dayjs(_date?.$d).format('YYYY-MM-DD')
    setBasicInfoDetails({ ...basicInfoDetails, dob: formatDate });
  }

  const isDisabledButton = () => {
    if (
      loading === false &&
      basicInfoDetails.firstName !== "" &&
      basicInfoDetails.dob !== "" && validatePAN(basicInfoDetails.panNumber) === true &&
      basicInfoDetails.panNumber !== "" && validateEmail(basicInfoDetails.email) === true &&
      basicInfoDetails.email !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleneedHelp = () => {
    setShowHelpModal(false);
  };

  const handleClose = () => {
    setShowHelpModal(!showHelpModal);
  };

  const formView = () => {
    return (
      <>
        <img src={PersonIcon} alt="name-gif" className="icon-gif" />
        <div className="fs-24 heading">Basic details</div>
        <form noValidate onSubmit={submitFn} id='form'>
          {/* <Box sx={{mt:3}}> */}
          

          {/*  First Name  */}
          <FormControl fullWidth margin="normal">
            <TextField
              fullWidth
              type="text"
              label="First Name"
              name="firstName"
              placeholder="Enter your first name"
              required
              value={basicInfoDetails.firstName}
              autoComplete="off"
              className="details-input"
              error={
                !validation.isValid
              }
              helperText={validation.message}
              // onKeyDown={(event:React.KeyboardEvent<HTMLInputElement>) => (/^[a-zA-Z]*$/).test(e.targte)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
            />
            
          </FormControl>

              {/*  Middle Name  */}

          <FormControl fullWidth margin="normal">
            <TextField
              fullWidth
              type="text"
              label="Middle Name"
              name="middleName"
              placeholder="Enter your middle name"
              value={basicInfoDetails.middleName}
              autoComplete="off"
              className="details-input"
              error={
                basicInfoDetails.middleName.length > 50 
              }
              helperText={
                basicInfoDetails.middleName.length > 50 ? "Your Middle Name should be 50 characters or less" : ""
              }
              // onKeyDown={(event:React.KeyboardEvent<HTMLInputElement>) => (/^[a-zA-Z]*$/).test(e.targte)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
            />
            
          </FormControl>


          {/*  Last Name  */}

          <FormControl fullWidth margin="normal">
            <TextField
              fullWidth
              type="text"
              label="Last Name"
              name="lastName"
              placeholder="Enter your last name"
              value={basicInfoDetails.lastName}
              autoComplete="off"
              className="details-input"
              error={
                basicInfoDetails.lastName.length > 50
              }
              helperText={
                basicInfoDetails.lastName.length > 50 ? "Your Last Name should be 50 characters or less" : ""
              }
              // onKeyDown={(event:React.KeyboardEvent<HTMLInputElement>) => (/^[a-zA-Z]*$/).test(e.targte)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
            />
            
          </FormControl>

          

          <FormControl fullWidth margin="normal">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of birth"
                name='dob'
                format="DD-MM-YYYY"
                onChange={handleDate}
                disableFuture={true}
              />
            </LocalizationProvider>
          </FormControl>


          <FormControl fullWidth margin="normal">
          <InputLabel id="gender-select">Gender</InputLabel>
            <Select  
            name="gender"
            label="Gender"
            labelId="gender-select"
            id="gender-dropdown"
            placeholder='Mention Your Gender'
            required
            value={basicInfoDetails.gender}
            onChange={(e)=>{
              setBasicInfoDetails((prev)=> {
                return {
                  ...prev,
                  gender:e.target.value
                }
              })
            }}
            >
            <MenuItem value={1}>Male</MenuItem>
            <MenuItem value={2}>Female</MenuItem>
            <MenuItem value={3}>Others</MenuItem>
            </Select>

          </FormControl>

 
          {/* <FormControl fullWidth margin="normal">
            <TextField
              type="date"
              name="dob"
              placeholder="Enter your date of birth"
              required
              className="details-input"
              value={basicInfoDetails.dob}
              error={
                basicInfoDetails.dob !== "" &&
                validateDob(basicInfoDetails.dob) === false
              }
              // max={new Date().toISOString().split("T")[0]} // Disable future date
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
            />
          </FormControl> */}

          <FormControl fullWidth margin="normal">
            <TextField
              label="PAN"
              type="text"
              className="details-input"
              name="panNumber"
              placeholder="Enter your PAN"
              required
              autoComplete="off"
              error={
                basicInfoDetails.panNumber !== "" &&
                validatePAN(basicInfoDetails.panNumber) === false
              }
              value={basicInfoDetails.panNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
              helperText={(basicInfoDetails.panNumber !== "" && validatePAN(basicInfoDetails.panNumber) === false) ? ValidationErrorMessage.panValidation : ""}
            />
          </FormControl>


          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              type="text"
              name="email"
              required
              className="details-input"
              placeholder="Enter your Email address"
              error={
                basicInfoDetails.email !== "" &&
                validateEmail(basicInfoDetails.email) === false
              }
              value={basicInfoDetails.email}
              autoComplete="off"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
              helperText={(basicInfoDetails.email !== "" && validateEmail(basicInfoDetails.email) === false) ? ValidationErrorMessage.emailValidtionError : ""}
            />
          </FormControl>


          {/* <Form.Group controlId="dob" className="floating-label mt-4">
          <FloatingLabel controlId="dobFloatLable" label="Date of birth">
            <Form.Control
              type="date"
              name="dob"
              placeholder="Enter your date of birth"
              required
              className="details-input"
              value={basicInfoDetails.dob}
              isInvalid={
                basicInfoDetails.dob !== "" &&
                validateDob(basicInfoDetails.dob) === false
              }
              max={new Date().toISOString().split("T")[0]} // Disable future date
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBasicInfoChange(e)
              }
            />
             <Form.Control.Feedback type="invalid">
              {ValidationErrorMessage.dobValidation}
            </Form.Control.Feedback>
            </FloatingLabel>
           
          </Form.Group>

          <Form.Group controlId="pan" className="floating-label mt-4">
            <FloatingLabel controlId="panFloatLable" label="PAN">
              <Form.Control
                type="text"
                className="details-input"
                name="panNumber"
                placeholder="Enter your PAN"
                required
                autoComplete="off"
                isInvalid={
                  basicInfoDetails.panNumber !== "" &&
                  validatePAN(basicInfoDetails.panNumber) === false
                }
                value={basicInfoDetails.panNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleBasicInfoChange(e)
                }
              />
                <Form.Control.Feedback type="invalid">
              {ValidationErrorMessage.panValidation}
            </Form.Control.Feedback>
            </FloatingLabel>
          
          </Form.Group>
          <Form.Group controlId="name" className="floating-label mt-4">
            <FloatingLabel controlId="emailFloatLable" label="Email">
              <Form.Control
                type="email"
                name="email"
                className="details-input"
                placeholder="Enter your Email address"
                isInvalid={
                  basicInfoDetails.email !== "" &&
                  validateEmail(basicInfoDetails.email) === false
                }
                value={basicInfoDetails.email}
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleBasicInfoChange(e)
                }
              />
               <Form.Control.Feedback type="invalid">
              {ValidationErrorMessage.emailValidtionError}
            </Form.Control.Feedback>
            </FloatingLabel>
           
          </Form.Group>  */}
          <div className="error-message">{errors}</div>
          <div className="mt-35">
            <AppButton
              text="Next"
              className="w-100 app-btn"
              isDisabled={isDisabledButton()}
              loading={loading}
              type="submit"
            />
          </div>
          {/* <Box sx={{ mt: 2 }} className="text-center">
            <a
              href=""
              className="app-text-color fs-14"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                handleneedHelp();
              }}
            >
              Need help?
            </a>
          </Box> */}
        </form>
      </>
    );
  };

  return (
    <>
      <div className="basic-details-view">
        <div className="app-logo w-100">
          <LogoIcon/>
          {/* <img src={Logo} alt="logo" />
          Logo */}
        </div>
        <div>
          <div className="app-progress-bar w-100">
            <ProgressBarComponent completedStatus={formCompletedStatus} />
          </div>
          <div className="details-form-view">{formView()}</div>
        </div>
      </div>
      {/* <NeedHelpModal showHelpModal={showHelpModal} handleClose={handleClose} /> */}
      <Fab
        color="primary"
        variant="extended"
        style={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            display: 'flex',
            zIndex: 1000,
        }}
        onClick={() => {
            const customerCode = token?.ecc.replaceAll("+","%2B")  || 'cus_code';
            const iv = env.KAPTURE_IV || 'cus_iv';
        
            const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        }}
    >
        <Box sx={{display:'flex',gap:'0.5rem',alignItems:'center',justifyContent:'center'}}>
          <ChatIcon /> 
          <Typography textTransform={'capitalize'}>
            Chat
          </Typography>
        </Box>
      </Fab>
    </>
  );
};

export default BasicDetails;
