import LandingPage from "../pages/LandingPage/LandingPage";
import Login from "../pages/Login/Login";
import CreditScore from '../pages/CreditScore/CreditScore';
import TermsAndConditons from "../components/TermsAndConditons";
import PrivacyPolicy from "../components/PrivacyPolicy";
import WeddingLoanBlog from "../pages/WeddingLoanBlog";
import { BudgetRq } from "../pages/WeddingLoanBlog/BudgetReq";
import WeddingLoan from "../pages/WeddingLoanBlog/weddingLoan";
import { NotAWeddingLoan } from "../pages/WeddingLoanBlog/NotAWeddingLoan";
import { DecideExpense } from "../pages/WeddingLoanBlog/DecideExpense";
import { DecideVendor } from "../pages/WeddingLoanBlog/DecideVendor";
import { NotExceedBug } from "../pages/WeddingLoanBlog/NotExceedBug";
import { KeyBenefits } from "../pages/WeddingLoanBlog/KeyBenefits";
import { EnsurePrivacy } from "../pages/WeddingLoanBlog/EnsurePrivacy";
import { Stakeholders } from "../pages/WeddingLoanBlog/stakeholders";
import { DocReq } from "../pages/WeddingLoanBlog/DocReq";
import { CalculateEMI } from "../pages/WeddingLoanBlog/CalculateEMI";
import { KeyTerms } from "../pages/WeddingLoanBlog/keyTerms";
import { KeyFact } from "../pages/WeddingLoanBlog/KeyFact";
import { Facility } from "../pages/WeddingLoanBlog/Facilitiy";
import { RevolvingCredit } from "../pages/WeddingLoanBlog/RevolvingCredit";
import { TypesOfLoan } from "../pages/WeddingLoanBlog/TypesOfLoan";
import AutoLogin from "../pages/Login/AutoLogin";
import GrievanceDetails from "../pages/GrievanceDetails";
import ProductDetails from "../pages/ProductDetails";


interface MetaConfig {
    title: string;
    description: string;
    keywords?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    canonical?: string;
    noindex?: boolean;
}

interface RouteConfig {
    path: string;
    element: React.ReactElement;
    meta: MetaConfig;
}

const publicRoutes: RouteConfig[] = [
    {
        path: '/', element: <LandingPage />,
        meta: {
            title: 'Wedding Loan | Apply for Marriage Loan starting at 10.99%',
            description: 'Make your dream wedding a reality with WeddingLoan.com! Explore personalized financing options for your marriage expenses with a dedicated Relationship Manager',
            keywords: `Wedding loan,Marriage loan,Wedding finance,Personal loan for weddings,Personal loan for marriage,Affordable wedding financing,Wedding budget assistance,Loans for marriage costs,Wedding expenses,Wedding planning loan,Financial help for weddings,Best wedding loan options`,
            ogTitle: 'Wedding Loan | Apply for Marriage Loan starting at 10.99%',
            ogDescription: 'Make your dream wedding a reality with WeddingLoan.com! Explore personalized financing options for your marriage expenses with a dedicated Relationship Manager',
        }
    },
    {
        path: '/login', element: <Login />,
        meta: {
            title: 'Login to Your WeddingLoan Account | Manage Your Financing Easily',
            description: `Access your WeddingLoan account to manage your marriage financing, check loan status, and view personalized loan options from leading banks & NBFC's`,
            keywords: `WeddingLoan login,Access wedding loan account,Manage wedding financing,Check loan status,Wedding loan dashboard,Personal loan management,Secure wedding loan login,Wedding financing account access,User account for wedding loans,Login to WeddingLoan`,
            ogTitle: 'Login to Your WeddingLoan Account | Manage Your Financing Easily',
            ogDescription: `Access your WeddingLoan account to manage your marriage financing, check loan status, and view personalized loan options from leading banks & NBFC's`
        }
    },
    {
        path: '/sso-login', element: <AutoLogin />,
        meta: {
            title: 'Login to Your WeddingLoan Account | Manage Your Financing Easily',
            description: `Access your WeddingLoan account to manage your marriage financing, check loan status, and view personalized loan options from leading banks & NBFC's`,
            keywords: `WeddingLoan login,Access wedding loan account,Manage wedding financing,Check loan status,Wedding loan dashboard,Personal loan management,Secure wedding loan login,Wedding financing account access,User account for wedding loans,Login to WeddingLoan`,
            ogTitle: 'Login to Your WeddingLoan Account | Manage Your Financing Easily',
            ogDescription: `Access your WeddingLoan account to manage your marriage financing, check loan status, and view personalized loan options from leading banks & NBFC's`
        }
    },
    {
        path: '/credit-score', element: <CreditScore />,
        meta: {
            title: 'Check Your FREE Credit Score | WeddingLoan.com',
            description: `Check your free credit score at WeddingLoan.com! Understand your credit status to improve and secure the best wedding loan options for your dream day`,
            keywords: `Free credit score check,Credit score for marriage loan,Improve credit score for loans,Wedding loan credit tips,Financing a wedding with your credit score,Credit score and wedding financing,Understanding your credit score for loans,Equifax credit score,Credit enquiry`,
            ogTitle: 'Check Your FREE Credit Score | WeddingLoan.com',
            ogDescription: 'Check your free credit score at WeddingLoan.com! Understand your credit status to improve and secure the best wedding loan options for your dream day',
        }
    },
    {
        path: '/terms-conditions', element: <TermsAndConditons />,
        meta: {
            title: 'Terms and Conditions',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/privacy-policy', element: <PrivacyPolicy />,
        meta: {
            title: 'Privacy Policy',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/blog', element: <WeddingLoanBlog />,
        meta: {
            title: 'Blog',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/budget-requirements", element: <BudgetRq />,
        meta: {
            title: 'Budget Requirements',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/wedding-loan", element: <WeddingLoan />,
        meta: {
            title: 'Blog-Wedding Loan',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/not-a-wedding-loan", element: <NotAWeddingLoan />,
        meta: {
            title: 'Blog-Not-Wedding-Loan',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/decide-ur-expense", element: <DecideExpense />,
        meta: {
            title: 'Decide Your Expense',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/decide-vendor", element: <DecideVendor />,
        meta: {
            title: 'Decide Your Vendor',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/not-exceed-budget", element: <NotExceedBug />,
        meta: {
            title: 'Not Exceed Budget',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/key-benefits", element: <KeyBenefits />,
        meta: {
            title: 'Key Benefits',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/ensure-privacy", element: <EnsurePrivacy />,
        meta: {
            title: 'Ensure Privacy',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/manage-stakeholders", element: <Stakeholders />,
        meta: {
            title: 'Manage StakeHolders',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/docs-requried", element: <DocReq />,
        meta: {
            title: 'Documents Required',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/calc-emi", element: <CalculateEMI />,
        meta: {
            title: 'Calculate EMI',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/key-terms", element: <KeyTerms />,
        meta: {
            title: 'Key Terms',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/key-fact", element: <KeyFact />,
        meta: {
            title: 'Key Facts',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/overdraft-facility", element: <Facility />,
        meta: {
            title: 'Overdraft Facility',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/revolving-credit", element: <RevolvingCredit />,
        meta: {
            title: 'Revolving Credit',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/blog/types-of-loan", element: <TypesOfLoan />,
        meta: {
            title: 'Types of Loan',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/details", element: <GrievanceDetails />,
        meta: {
            title: 'Grievance Officer Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/product-details", element: <ProductDetails />,
        meta: {
            title: 'Product Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: "/grievance-officer-details", element: <GrievanceDetails />,
        meta: {
            title: 'Grievance Officer Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    }

];


export default publicRoutes