const SOURCES: { [key: string]: number } = {
	BM: 1,
	CBS: 2,
	SELF: 3,
	BM_SMS: 4,
	CBS_SMS: 5,
	BM_EMAIL: 6,
	CBS_EMAIL: 7,
};

export const sourceMapper = () => {
	const source: string = sessionStorage.getItem('source') ?? 'SELF';
	return SOURCES[source];
};
