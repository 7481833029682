import { Box, useMediaQuery } from "@mui/material";

const GrievanceDetails = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 500px)");
  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        mt={3}
        sx={{ fontWeight: "600", fontSize: "16px", textAlign: "center" }}
      >
        Grievance Redressal Officer Details
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          // padding: "0px 20px",
        }}
      >
        <Box
          sx={{
            border: "1px solid",
            width: isSmallDevice ? "auto" : "480px",
            padding: "7px 8px 16px 8px",
            marginTop: "12px",
          }}
        >
          <Box sx={{ fontWeight: "bold", marginTop: "15px" }}>
            WeddingLoan.com
          </Box>
          <Box mt={2}>
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Name:{" "}
              </Box>
              <Box component="span"> Mr. Karthikeyan Krishnasamy</Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Address:
              </Box>
              <Box component="span">
                {" "}
                M/S. Matrimony.com Limited, No. 94, TVH Beliciaa Towers, Tower
                2, 5th floor, MRC Nagar, Chennai – 600028
              </Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Email:
              </Box>
              <Box component="span">
                {" "}
                <a
                  href="mailto:help@weddingloan.com"
                  style={{ wordBreak: "break-all" }}
                >
                  grievanceofficer@weddingloan.com
                </a>
              </Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Call at:
              </Box>
              <Box component="span"> +91-9841129361</Box>
            </Box>
            <Box mt={1}>(Lines are open Mon-Sat from 9:30am to 6:30pm)</Box>
          </Box>
        </Box>
      </Box>
      <Box   sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          // padding: "0px 20px",
        }}>
        <Box
          sx={{ fontWeight: "bold", marginTop: "25px", textAlign: "center" }}
        >
          Our Lending Partners
        </Box>
        <Box
          sx={{
            border: "1px solid",
            width: isSmallDevice ? "auto" : "480px",
            padding: "7px 8px 16px 8px",
            marginTop: "12px",
          }}
        >
          <Box sx={{ fontWeight: "bold", marginTop: "15px" }}>
            Bhanix Finance and Investment Limited
          </Box>
          <Box mt={2}>
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Name:{" "}
              </Box>
              <Box component="span"> Ms. Pushpinder Kaur</Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Address:
              </Box>
              <Box component="span">
                {" "}
                5th Floor, Paville House, Twin Towers Lane, Prabhadevi, Mumbai
              </Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Email:
              </Box>
              <Box component="span">
                {" "}
                <a
                  href="mailto:help@weddingloan.com"
                  style={{ wordBreak: "break-all" }}
                >
                  Grievance@Bhanix.in
                </a>
              </Box>
            </Box>
            <br />
            <Box component="span">
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Call at:
              </Box>
              <Box component="span"> +91-89983107444</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GrievanceDetails;
