import { Box, Container, Typography } from "@mui/material";
import "./TermsAndConditions.scss";
import { styled } from "@mui/material/styles";

const ParagraphText = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "16px",
});

const boldFontStyle = {
  color: "#000",
  fontSize: "20px",
  fontWeight: 600,
};

const TermsAndConditons = () => {
  return (
    <Container
      sx={{ marginTop: "25px", paddingBottom: "20px", fontFamily: "Roboto" }}
    >
      <ParagraphText sx={boldFontStyle}>
        <u>Terms and Conditions</u>
      </ParagraphText>
      <ParagraphText mt={4} sx={{ fontFamily: "Roboto" }}>
        This document is an electronic record in Terms of the Information
        Technology Act, 2000 and Rules thereunder pertaining to electronic
        records as applicable and amended. This electronic record is generated
        by a computer system and does not require any physical or digital
        signatures.{" "}
      </ParagraphText>
      <ParagraphText mt={2}>
        This document is published in accordance with the provisions of Rule 3
        (1) of the Information Technology (Intermediaries Guidelines and Digital
        Media Ethics Code) Rules, 2021 that require publishing the rules and
        regulations, privacy policy and the Terms and Conditions for access or
        usage of www.weddingloan.com (the "Website").{" "}
      </ParagraphText>
      <ParagraphText mt={3}>
        These Terms and Conditions in relation to the usage and access of
        “WEDDINGLOAN.COM” is between WEDDINGLOAN.COM, a brand owned by
        matrimony.com, CIN: L63090TN2001PLC047432, a company registered under
        the provisions of Companies Act, 1956 and having its registered office
        at 5th floor, Tower 2, TVH Beliciaa towers, MRC Nagar, Chennai - 600028
        (hereinafter referred to as “Company”) and its Users. Company owns,
        retains and has complete rights in WEDDINGLOAN.COM Website (hereinafter
        referred to as “WL” or “We” or “Us” or “Our”) and the registered Users
        of the weddingloan.com (hereinafter referred to as “You” or “Your” or
        “Yourself” or “User”) describe the Terms on which We offer You access to
        the Website and such other services as are incidental and ancillary
        thereto (“ WEDDINGLOAN.COM Services”)
      </ParagraphText>
      <ParagraphText mt={2}>
        YOU ARE FULLY AWARE THAT WL DOES NOT EXTEND ANY FINANCIAL ASSISTANCE. WL
        IS ONLY A PLATFORM REFERRING THE PROSPECTIVE BORROWERS WHO INTEND TO
        AVAIL WEDDING LOAN TO THE BANKING PARTNERS / PROSPECTIVE LENDERS. WL
        ONLY REFERS THE PROSPECTIVE BORROWERS TO THE BANKING PARTNERS /
        PROSPECTIVE LENDERS AND FINANCIAL TRANSACTION IS ONLY BETWEEN THE
        BORROWER AND THE LENDER.
      </ParagraphText>
      <ParagraphText mt={2}>
        THIS WEBSITE/ APP IS A PLATFORM REFERRING INDIVIDUALS SEEKING LOANS FOR
        WEDDING TO THE PROSPECTIVE LENDERS. WE ARE MERELY AN INTERMEDIARY,
        FACILITATING A PLATFORM FOR PROVIDING AN OPPORTUNITY TO INDIVIDUALS FOR
        AVAILING WEDDING LOAN FROM OUR BANKING PARTNERS. PLEASE READ THIS
        AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR
        LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS
        AND EXCLUSIONS BY USING THIS WEBSITE/MOBILE APPLICATION. YOU AGREE TO
        EACH OF THE TERMS SET FORTH HEREIN. IF YOU DO NOT AGREE WITH ANY TERM OR
        CONDITION SET FORTH HEREIN OR ARE UNWILLING TO ABIDE BY ANY TERM OR
        CONDITION SET FORTH HEREIN, DO NOT USE THIS WEBSITE/MOBILE APPLICATION.
      </ParagraphText>
      <ParagraphText mt={2}>
        YOU AGREE THAT WE 
        <Box
          component="span"
          sx={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}
        >DO NOT 
        </Box>
        OFFER WEDDING LOANS TO OUR USERS. BASED ON YOUR
        EXPLICIT CONSENT, WE MERELY SHARE YOUR REQUIREMENT FOR WEDDING LOAN TO
        OUR BANKING PARTNER. OUR BANKING PARTNERS AT THEIR SOLE DISCRETION MAY
        OFFER YOU LOAN FOR WEDDING.
      </ParagraphText>
      <ParagraphText mt={2}>
        CAUTION DO NOT INVOLVE IN ANY CASH TRANSACTION. UNDER ANY CIRCUMSTANCES,
        WE WILL NOT BE LIABLE FOR ANY TRANSACTION, INCLUDING MONEY TRANSACTIONS
        BETWEEN THE USERS OF WL OR BETWEEN THE USERS OF WL AND EMPLOYEES OF WL
        OR BETWEEN THE USER AND BANKING PARTNERS. WL NEVER COLLECT ANY PAYMENT
        OR CASH FROM ITS USERS AND ANY SUCH REQUEST FOR PAYMENT OF ANY AMOUNT OR
        CASH MUST BE IMMEDIATELY REPORTED TO WL BY THE USER.
      </ParagraphText>
      <ParagraphText mt={2}>
        BASED ON YOUR CONSENT, WE RECEIVE YOUR CREDIT REPORT, AND CREDIT SCORE
        FROM CREDIT BUREAUS, AND SHARE THE SAME ON AN
        <Box
          component="span"
          sx={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}
        >
          “AS IS”
        </Box>
        BASIS TO OUR BANKING PARTNERS. NOTHING IN THIS AGREEMENT SHALL BE
        CONSTRUED AS A WARRANTY, REPRESENTATION OR INDUCEMENT WITH RESPECT TO
        THE CONTENT, ACCURACY, OR SUFFICIENCY OF THE INFORMATION. WE ARE NOT
        RESPONSIBLE FOR THE CREDIT REPORTS, WE DO NOT MODIFY ANY CONTENT OF THE
        REPORT GIVEN BY CREDIT BUREAUS.
      </ParagraphText>
      <ol style={{ listStyle: "decimal !important" }}>
        <li>
          <u>General</u>
          <ol style={{ listStyle: "decimal !important", marginTop: "10px" }}>
            <li style={{ marginTop: "10px" }}>
              This document (“Terms and Conditions” or “Terms”) is an electronic
              record in the form of an electronic contract and does not require
              any physical, electronic, or digital signature. These Terms and
              Conditions are legally binding documents between You, and Company.
              Once you access our website, you agree and accept to these Terms
              and Conditions which constitutes a binding agreement between us.
              (by means of an electronic record) and will govern the
              relationship between you and Company, for use of WEDDINGLOAN
              Services.
            </li>
            <li style={{ marginTop: "10px" }}>
              These Terms and Conditions together with the Privacy Policy
              available together with all other notices, disclaimers, guidelines
              appearing on the Website from time to time (collectively referred
              to as “Agreement(s)”) constitute the entire agreement upon which
              You are allowed to access and use the Website and avail the
              Services provided that are on as-in basis only.
            </li>
            <li style={{ marginTop: "10px" }}>
              If You are concerned about how your data is being used by the
              Website then refer to Our Privacy Policy, which explains how We
              treat your personal data and protect Your privacy. By using the
              Website, You agree that We can use such data in accordance with
              Our Privacy Policy.
            </li>
            <li style={{ marginTop: "10px" }}>
              The subscription / agreement between Company and the User is not a
              "non-poach agreement" nor can the same be termed or used as an
              alternative to "non-poach agreement" in as much as Company/ WL is
              a public site and all information posted by the Company goes to
              the public domain except information / data which is specifically
              assigned a non-public / private character.
            </li>
          </ol>
        </li>
        <li>
          <u>Eligibility</u>
          <ol style={{ listStyle: "decimal !important", marginTop: "10px" }}>
            <li style={{ marginTop: "10px" }}>
              To avail WL Service, you must register on the Website and create
              an account. By registering with WL for this Service, you agree and
              certify that
            </li>
            <li style={{ marginTop: "10px" }}>
              You are 18 years of age or above;
            </li>
            <li style={{ marginTop: "10px" }}>
              Any information you provide to us, both when you register and in
              the future, is and will be true, accurate, current and complete;
            </li>
            <li style={{ marginTop: "10px" }}>
              You are only registering an account for yourself and agree not to
              use this Service to attempt to obtain/receive credit information
              about any person other than you;
            </li>
            <li style={{ marginTop: "10px" }}>
              You will keep all information up to date;
            </li>
            <li style={{ marginTop: "10px" }}>
              You have voluntarily registered and WL has not made any promises
              or representations to you in order to induce you to provide your
              credit information or seek any consent or authorization in this
              regard.{" "}
            </li>
          </ol>
        </li>
        <li>
          <u>Registration</u>
          <ol style={{ listStyle: "decimal !important", marginTop: "10px" }}>
            <li style={{ marginTop: "10px" }}>
              You will have to register on the Website to use WL functionality
              or to access WL Services. If You do register, We allow You to
              create Your account in which information is stored about You
              (called your “Account”). Your Account on our Website allows Us to
              provide You better facilities and Services including but not
              limited to providing you with easy access to information about
              you, your use of the WL Services.
            </li>
            <li style={{ marginTop: "10px" }}>
              In order to register, You need to supply Us with Your name, date
              of birth, email address, mobile number, PAN card number, permanent
              residential address, Your salary amount, current residential
              address, nature of Your employment, place of employment etc. All
              information so provided is kept on Our services and used in
              accordance with Our Terms and Conditions and Privacy Policy. Users
              undertake that all information shared will at all times be
              accurate and not be misleading. It is Your responsibility to
              provide correct details and under any circumstances, We cannot be
              responsible for the consequences of any incorrect details that You
              have provided.
            </li>
            <li style={{ marginTop: "10px" }}>
              During Registration, You will also need to set a password. You
              must keep the password confidential and immediately notify Us if
              any unauthorized third party becomes aware of that password or if
              there is any unauthorized use of Your email address or any breach
              of security known to You. You agree that we are not responsible or
              liable in an event that a person to whom Your password is
              disclosed uses (and/or transacts via) our Services and Website.
              Please note that You are entirely responsible if you do not
              maintain the confidentiality of Your password.
            </li>
            <li style={{ marginTop: "10px" }}>
              It is mandatory that all Accounts be registered with a valid
              personal email address / phone number that is accessed by You
              regularly. Any Accounts which have been registered with someone
              else’s email address or with temporary email addresses may be
              closed by WL without notice. We may require Users to re-validate
              their Accounts if and when it is so required.
            </li>
          </ol>
        </li>
        <li>
          You hereby authorize WL to do all of the following in connection with
          providing you the Services:
        </li>
      </ol>
      <ol style={{ marginTop: "10px", marginLeft: "30px" }} start={1} type="1">
        <li style={{ marginTop: "10px", fontWeight: "bold" }}>
          Credit Information Report Service
          {/* <br /> */}
          <div style={{ fontWeight: "normal" }}>
            Credit Score Service ("Service") is an online service that enables
            you to access your credit score and credit report as supplied by our
            credit bureaus partners ("Credit bureaus”/ “Credit Information
            Companies"). The Service consists of receipt of your credit report
            and credit score and sharing the same with our banking partners. You
            undertake to abide by the
          </div>
          <ol style={{ marginTop: "10px", fontWeight: "normal" }}>
            <li style={{ marginTop: "10px" }}>
              You agree that by submitting your details on this Website and
              requesting the Service, you provide express and voluntary written
              consent to WL, authorizing and appointing WL as your lawful
              representative, for the purposes, including but not limited to
              instruct the Credit bureaus, its affiliates, to obtain, receive
              and compile your credit information i.e. free credit reports and
              credit scores, from the Credit bureaus on your behalf, viewing
              your credit report containing your personal and financial
              information and use it in the manner consistent with these Terms
              of Service.
            </li>
            <li style={{ marginTop: "15px" }}>
              You agree that this Service is provided to help you better
              understand your credit information and provide you with
              personalized offers for products and services from WL and/or its
              business/marketing partners.
            </li>
            <li style={{ marginTop: "15px" }}>
              By providing your authorization, we will create your WL credit
              information account section under “My Account” on the Website
            </li>
            <li style={{ marginTop: "15px" }}>
              Further, you hereby expressly grant consent to WL to direct the
              Credit bureaus to deliver and/or transfer your credit report to WL
              on your behalf.
            </li>
            <li style={{ marginTop: "15px" }}>
              You also agree that your authorization can be automatically
              assigned to a Credit bureau(s) and this Service shall be provided
              to you in the manner consistent with the terms and conditions of
              the arrangement between WL and its Credit bureau partners.
            </li>
            <li style={{ marginTop: "15px" }}>
              You understand that while providing your credit report is free of
              cost, however, WL may charge you, at its sole discretion, for
              other services such as assisting you to understand your credit
              report etc. Such charges may be revised by WL from time to time.
            </li>
            <li style={{ marginTop: "15px" }}>
              You agree that Additional Standard terms and conditions of the
              Credit bureaus CIBIL / EQUIFAX / EXPERIAN / CRIF Highmark / will
              apply to your use of this Service.
            </li>
            <li style={{ marginTop: "15px" }}>
              You authorize WL to request and receive your credit report, and
              credit score from Credit bureaus, including but not limited to a
              copy of your consumer credit report including credit score, at any
              time for (i) a limited period of six months or (ii) till such time
              the credit information is required to be retained to satisfy the
              purpose for which it was provided or (iii) until you withdraw your
              consent to store such Consumer Credit Information whichever is
              earlier and/or as per applicable governing laws and thereafter,
              the stored Consumer Credit Information shall be deleted, purged or
              expunged by the Company. Retain a copy of your credit information
              as per applicable governing laws, along with the other information
              you have given us access under this authorization, for use in
              accordance with terms and conditions of this Service, WL’s Terms
              of Use and Privacy Policy.
            </li>
            <li style={{ marginTop: "15px" }}>
              To assign/transfer your authorization to different Credit bureaus,
              in any circumstance whatsoever, and accordingly provide credit
              report from such Credit bureau(s). The consent provided by you at
              the time of registration under these Terms of Service shall be
              valid for all Credit bureau(s) partnered with us.
            </li>
            <li style={{ marginTop: "15px" }}>
              For obtaining your credit report, you agree that WL can reapply to
              the Credit bureau(s) every 30 (thirty) days through multiple
              attempts, in order to provide you with your credit report. In the
              event WL is unable to obtain your credit report, for any reason
              whatsoever, you agree and acknowledge that you shall not hold WL
              liable in any manner. You understand and acknowledge that in the
              event WL is unable to obtain your credit report, for any reason
              whatsoever WL’s right to reapply to obtain your credit report
              shall be valid for a period of 18 (eighteen) months from the date
              you have applied on the Website for availing this Service, where
              you had provided consent to WL for directing the Credit bureaus to
              deliver and/or transfer your credit information to WL on your
              behalf.
            </li>
            <li style={{ marginTop: "15px" }}>
              The consent obtained from you would be valid for a period of 180
              days. We shall conduct periodic credit bureau checks every 30 days
              and the same shall be carried out till the end of the subscription
              period i.e. until expire of 180 days.
            </li>
          </ol>
        </li>
        <li>
          <u> Changes to the Website/Mobile Application </u>
          <div style={{ marginTop: "10px" }}>
            The registration to Website is currently made available to you free
            of charge for only personal and non-commercial use. We reserve the
            right, in our sole discretion, without any notice to you, at any
            time and from time to time, temporarily or permanently, in whole or
            in part, to:
          </div>
        </li>
        <ul
          style={{
            listStyleType: "lower-latin",
            marginTop: "10px",
            fontWeight: "normal",
            marginLeft: "125px",
          }}
        >
          <li style={{ marginTop: "10px" }}>
            modify, suspend, terminate, withdraw, or discontinue all or any part
            of the WL services provided through the Website without compensation
            of any kind whether monetary or not to you, including, but not
            limited to:
          </li>
          <li style={{ marginTop: "15px" }}>
            charge a fee for usage of the Website;
          </li>
          <li style={{ marginTop: "15px" }}>
            charge fees for upgrades to the Website;
          </li>
          <li style={{ marginTop: "15px" }}>
            modify or waive any fees charged in connection with all or any part
            of the Website; or
          </li>
          <li style={{ marginTop: "15px" }}>
            offer opportunities to some or all Users of all or any part of the
            Website.
          </li>
        </ul>
        <div>
          You agree that neither Company nor any of its affiliates, shall be
          liable to you or to any third party for any modification, suspension,
          or discontinuance of all or any part of the Website in whole or in
          part, or of any service, content, or feature offered through the
          Website/Mobile Application.
        </div>
        <li>
          {" "}
          <u style={{ fontWeight: "bold" }}>Ownership</u> <br />
          <div style={{ marginTop: "15px" }}>
            The Website (including, without limitation, any material, software,
            code, files, all contents, and images contained in or generated by
            the Software, accompanying data, and other embedded software,
            including third-party software (collectively, “WL Content”), the
            Website updates and upgrades, and documentation and accompanying
            fonts (collectively, “WL Documentation”) – whether in read-only
            memory, on any other media, or in any other form – are owned by Us
            or Our licensors and are protected by applicable law, including
            copyright, trademark, patent, and/or trade-secret law. Neither title
            nor any intellectual property rights are transferred to You, but
            rather remain with Us, or the relevant third party, which owns full
            and complete title to the Website, WL Content, Website updates and
            upgrades, and WL Documentation. We reserve all rights not expressly
            granted to You, and except as provided in this Agreement, the rights
            granted herein are non-transferable and do not include any
            intellectual property rights.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Types of Content </u> <br />
          You will be able to access three types of content on the Website:{" "}
          <ul
            style={{
              listStyleType: "lower-latin",
              marginTop: "10px",
              marginLeft: "45px",
            }}
          >
            <li style={{ marginTop: "0px" }}>
              content that You upload and provide (“Your content”);{" "}
            </li>
            <li style={{ marginTop: "1px" }}>
              content that other Users provide (“User content”);
            </li>
            <li style={{ marginTop: "1px" }}>
              content that We provide (“Our content”).{" "}
            </li>
          </ul>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Your Content</u>
          <br />
          WL Services allow the submission of information, content and materials
          by You and other Users, and the hosting, sharing and/or publishing of
          such user submissions. You shall be solely responsible for Your own
          user submissions (“Your Content”) and the consequences of posting or
          publishing them. In connection with Your Content, You affirm,
          represent, and warrant that,
          <ul
            style={{
              marginTop: "10px",
              marginLeft: "45px",
              listStyleType: "lower-latin",
            }}
          >
            <li style={{ marginTop: "10px" }}>
              You own, or have the necessary licenses, rights, consents, and
              permissions to use, and authorize the Services to use, all
              intellectual property and any other proprietary rights in and to
              any and all of Your Content to enable inclusion and use of Your
              Content in the manner contemplated by the Service and these Terms;
            </li>
            <li style={{ marginTop: "1px" }}>
              You shall retain all of Your ownership rights in Your Content.
            </li>
          </ul>
          ‘WL’ assumes no responsibility whatsoever in connection with or
          arising from user submissions. We assume no responsibility for
          actively monitoring user submissions for inappropriate or explicit
          content. If at any time We choose to, in Our sole discretion, to
          monitor user submissions, We may do so without any intimation to You.
          We however retain the rights to enquire, restrict or delete any user
          submission or Content upon receipt of complaint from and other User.
          <br />
          We process and use your credit information in the following ways: -{" "}
          <ol
            style={{ marginTop: "10px", marginLeft: "45px" }}
            start={1}
            type="a"
          >
            <li style={{ marginTop: "10px" }}>
              To present you an overview of your credit profile including
              detailed information of all your credit accounts, where available;
            </li>
            <li style={{ marginTop: "15px" }}>
              To provide you with customized recommendations and personalized
              offers of the products and services of WL and/or its business
              partners/ affiliates via email, text, call or online display or
              other modes/channels at the sole discretion of WL;
            </li>
            <li style={{ marginTop: "15px" }}>
              To aggregate anonymous data for the purpose of data analytics,
              reporting, historical or comparative information or insights about
              your credit data, through third parties, in accordance with
              applicable laws;
            </li>
            <li style={{ marginTop: "15px" }}>
              To present you with reward / loyalty programs of WL from time to
              time and when you avail these programs, then you shall be bound by
              their respective terms and conditions (which shall be notified
              from time to time) including these Terms of Service;
            </li>
          </ol>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Responsibility for Your Content</u>
          <br />
          <div style={{ marginTop: "10px" }}>
            You assume all risks/ liability/ ownership associated with Your
            Content, including anyone’s reliance on its quality, accuracy, or
            reliability, or any disclosure by You of information in Your Content
            that makes You personally identifiable or provides others with
            information to locate You or determine places that You have visited,
            are visiting or are planning to visit. You may expose Yourself to
            liability if, for example, Your Content contains material that is
            false, intentionally misleading, or defamatory; violates any third
            party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right; contains material that
            is unlawful, including illegal hate speech or pornography; exploits
            or otherwise harms minors; or violates or advocates the violation of
            any law or regulation.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Our Right to use Your Content </u>
          <br />
          <div style={{ marginTop: "10px" }}>
          We shall never claim ownership of Your Content. However, You grant Us a perpetual, worldwide, royalty-free, non-exclusive license to use, distribute, reproduce, modify, translate, adapt, publicly perform and publicly display Your Content that is public in nature, in connection with Your use of the Service, for any purpose permitted under Our Privacy Policy and as necessary for posting, displaying, distributing and storing Your Content on the Service. You also grant the Users of the WL Service the right to access Your Content in connection with their use of the Service. You understand that the technical processing and transmission of data associated with Your Content may involve 
          </div>
          <ul
            style={{
              listStyleType: "lower-latin",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
          >
            <li>transmissions over various networks; and </li>
            <li>
            changes to conform and adapt to technical requirements of servers, connecting networks or devices.
            </li>
          </ul>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>
            {" "}
            Medium of Communication to WL Users
          </u>
          <br />
          <ol
            style={{
              listStyleType: "decimal",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
            start={1}
          >
            <li>
              WL Users hereby consents for receiving communication including
              promotional communications through any medium of communications
              such as electronic mails, calls, SMS or through Whatsapp messages
              from Company or other portals owned by the Company or their
              alliance partners.
            </li>
            <li>
              WL User confirms that the mobile number submitted to it for
              verification or alternative number if any provided, is not
              registered with the Do Not Disturb / National Customer Preference
              Register and they shall not initiate any complaint. WL Member
              further confirms that even if WL Member is registered with the
              telecom service provider under the category Do Not Disturb /
              National Customer Preference Register the calls from WL either to
              the verified mobile number or alternative number if any provided
              shall not be treated as promotional calls.
            </li>
          </ol>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Feedback </u>
          <br />
          <div style={{ marginTop: "10px" }}>
            Any feedback you provide to WL shall be deemed to be
            non-confidential. WL shall be free to use such feedback/information
            on an unrestricted basis. Further, by submitting the feedback, you
            represent and warrant that (i) your feedback does not contain
            confidential or proprietary information of yourself or third
            parties; (ii) WL member is not under any obligation of
            confidentiality, express or implied, with respect to the feedback;
            (iii) you are not entitled to any compensation or reimbursement of
            any kind from WL for the feedback under any circumstances.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Advertising </u>
          <br />
          <div style={{ marginTop: "10px" }}>
            WL and its licensees may publicly display advertisements and other
            information adjacent to Your Content. You are not entitled to any
            compensation for such advertisements. The manner, mode and extent of
            such advertising are subject to change without specific notice to
            You.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>
            Disclaimer and Intellectual Property{" "}
          </u>
          <br />
          <ol
            style={{
              listStyleType: "decimal",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
            start={1}
          >
            <li>
              <span style={{ fontWeight: "bold" }}>Disclaimer:</span>
              YOU UNDERSTAND THE FACT THAT YOU HAVE NO RIGHTS TO THE WEBSITE,
              CONTENT, DOCUMENTATION, MARKS, OR ANY OTHER PROPERTY EXCEPT AS
              INDICATED IN THIS AGREEMENT. WL TAKES THE PROTECTION OF
              INTELLECTUAL PROPERTY RIGHTS, INCLUDING COPYRIGHTS, VERY SERIOUSLY
              (MENTIONED HEREINAFTER). WE WILL TERMINATE YOUR ACCESS TO, OR USE
              OF, ALL OR ANY PART OF THE WEBSITE WITHOUT NOTICE TO YOU, IF YOU
              INFRINGE OR MISAPPROPRIATE THE INTELLECTUAL PROPERTY RIGHTS,
              INCLUDING COPYRIGHTS, OF WL OR OTHERS. FURTHER, WE RESERVE ALL
              OTHER RIGHTS WITH RESPECT TO ITS INTELLECTUAL PROPERTY.
            </li>
            <li style={{ marginTop: "10px" }}>
              YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.
              WE DO NOT CONDUCT BACKGROUND CHECKS ON ITS USERS OR OTHERWISE
              INQUIRE INTO THE BACKGROUND OF ITS USERS. FURTHER, WE MAKE NO
              REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS. YOU
              SHALL NOT HOLD COMPANY LIABLE FOR ANY LOSS OR DAMAGE (PHYSICAL,
              PSYCHOLOGICAL OR FINANCIAL OR ANY OTHER DAMAGE) THAT OCCURS TO YOU
              IN LIEU OF USE OF THE WEBSITE AND/OR INTERACTION WITH ANY USERS OF
              THE WEBSITE.
            </li>
            <li style={{ marginTop: "10px" }}>
              THE COMPANY WILL NOT BE PARTY TO ANY LEGAL PROCEEDINGS BETWEEN A
              USER AND A PARTY CONTACTED THROUGH THE SITE. IN CASE THE COMPANY
              IS IMPLICATED IN ANY LEGAL PROCEEDINGS, COSTS WILL BE RECOVERED
              FROM THE PARTY THAT NAMES THE COMPANY. THE COMPANY HOWEVER WILL
              ABIDE WITH ANY COURT ORDER SERVED ON IT THROUGH DUE PROCESS.
            </li>
            <li style={{ marginTop: "10px" }}>
              IN CASE A PERSON USING THE WORLD WIDE WEB/INTERNET RECEIVES A SPAM
              OR VIRUS WHICH INCLUDES A LINK TO WL OR TO ANY OTHER SITE
              MAINTAINED, OPERATED OR OWNED BY THE COMPANY, IT SHOULD NOT BE
              HELD RESPONSIBLE FOR THE SAME. COMPANY ASSUMES NO RESPONSIBILITY
              FOR SUCH MAILS.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Intellectual Property:</span>
              <br />
              <ul
                style={{
                  listStyleType: "lower-latin",
                  marginTop: "10px",
                  marginLeft: "45px",
                }}
              >
                <li>
                  All contents available on the Website and the processes, and
                  their selection and arrangement, including but not limited to
                  all text, graphics, User interfaces, visual interfaces, sounds
                  and music (if any), artwork and computer code (collectively,
                  the “Software Content”) on the Website is owned and controlled
                  by Company or its licensors and the design, structure,
                  selection, coordination, expression, look and feel and
                  arrangement of such Content is protected by copyright, patent
                  and trademark laws, and various other intellectual property
                  rights. Through Your use of the Website, by no means are any
                  rights impliedly or expressly granted to You in respect of
                  such Content. We reserve the right to change or modify the
                  Content from time to time at Our sole discretion.
                </li>
                <li>
                  The trademarks, logos and service marks displayed on the
                  Website (“Marks”) are the property of Company. The logos and
                  services as provided by the vendors/vendor as displayed on the
                  Website have intellectual property rights attached to them.
                  You are not permitted to use the Marks without Our prior
                  consent.
                </li>
                <li>
                  Unless as stated on the Website as otherwise, indicated or
                  anything contained to the contrary or any proprietary material
                  owned by a third party and so expressly mentioned, We own all
                  intellectual property rights to and into the trademark “WL,
                  and the Website, including, without limitation, any and all
                  rights, title and interest in and to copyright, related
                  rights, utility models, designs, know-how, trade secrets,
                  goodwill, source code, meta tags, databases, text, content,
                  graphics, icons, and hyperlinks.
                </li>
                <li>
                  You acknowledge and agree that You shall not copy, republish,
                  post, display, translate, transmit, reproduce or distribute
                  any Content through any medium without obtaining the necessary
                  authorization from the Software or thirty party owner of such
                  Content.
                </li>
                <li>
                  Any other text, content, graphics, User interfaces,
                  trademarks, logos, sounds, artwork, and other intellectual
                  property appearing on the Website are owned, controlled or
                  licensed by Us and are protected by copyright, trademark and
                  other intellectual property law rights. All right, title and
                  interest in and to Our Content remains with Us at all times.
                </li>
                <li>
                  We grant You a non-exclusive, limited, personal,
                  non-transferable, revocable license to access and use Our
                  Content, without the right to sublicense, under the following
                  conditions:
                  <br />
                  <ul
                    style={{
                      listStyleType: "lower-roman",
                      marginTop: "10px",
                      marginLeft: "45px",
                    }}
                  >
                    <li>
                      You shall not use, sell, modify, or distribute Our Content
                      except as permitted by the functionality of the Website;{" "}
                    </li>
                    <li>
                      You shall not use Our name in metatags, keywords and/or
                      hidden text;
                    </li>
                    <li>
                      You shall not create derivative works from Our Content or
                      commercially exploit Our Content, in whole or in part, in
                      any way; and
                    </li>
                    <li>You shall use Our Content for lawful purposes only.</li>
                  </ul>
                </li>
                <li>
                  We reserve all other rights. If You breach any of the Terms,
                  the above license will terminate automatically, and You must
                  immediately destroy any of Our Content that You have
                  downloaded or otherwise obtained.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Grievance Officer </u>
          <br />
          <div style={{ marginTop: "15px" }}>
            In the event You come across any violation by another User including
            but not limited to having content in the web site that is obscene,
            menacing, grossly offensive, harming minors, infringing copyright,
            patents, etc., or another User is impersonating etc. You may then
            please be free to provide Your concerns in writing or email Us with
            digital signature to
            <br />
            Email:
            <a
              href="mailto:grievanceofficer@matrimony.com"
              style={{ marginLeft: "5px" }}
            >
              grievanceofficer@weddingloan.com
            </a>{" "}
            ,
            <br />
            Grievance Officer: Mr Karthikeyan Krishnasamy
            <br />
            The Grievance officer shall be available between 10 am till 6 pm
            Indian Standard Time from Monday to Saturday excluding Sunday and
            public holidays in India. The Grievance officer is appointed as per
            Rule 3 (11) of The Information Technology (Intermediaries
            Guidelines) Rules, 2011. Please see in other sections within this
            User agreement for comprehensive list of offences that Grievance
            Officer will handle.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Restrictions on WL : </u>
          <br />
          <div style={{ marginTop: "15px" }}>
            You can complain about User Content by contacting Our Grievance
            officer at
            <a
              href="mailto:grievanceofficer@matrimony.com"
              style={{ marginLeft: "5px" }}
            >
              grievanceofficer@weddingloan.com
            </a>{" "}
            outlining the abuse and/or complaint, or You can block and/or report
            a User directly from their profile or in chat by clicking the “Block
            & Report” link. Blocking a User will prevent them from being able to
            contact You via WL. We take all complaints seriously and will aim to
            investigate and respond to all User complaints as early as possible.
            Any content which We consider in Our discretion to be in breach of
            these Terms will be deleted and We may suspend or terminate the
            offending User’s account.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Privacy </u>
          <br />
          <div style={{ marginTop: "15px" }}>
            For information on how We collect, use and share Your personal data,
            please check out Our Privacy Policy. By using the Website, You agree
            that We can use such data in accordance with Our Privacy Policy.
          </div>
          <div style={{ marginTop: "15px" }}>
            Please be aware that internet transmissions are never completely
            private or secure and that any message or information You send using
            the Website may be read or intercepted by others.{" "}
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Termination of this Agreement </u>
          <br />
          <ol
            style={{
              listStyleType: "decimal",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
            start={1}
          >
            <li>
              <span style={{ fontWeight: "bold" }}>By us:</span>
              <br />
              <ul
                style={{
                  listStyleType: "lower-roman",
                  fontWeight: "normal",
                  marginLeft: "25px",
                }}
              >
                <li>
                  Sometimes people forget about the Terms and post or upload
                  content or act in a way while on WL which is not consistent
                  with its purpose. If We think that You may be one of those
                  people, then We reserve the right at Our sole discretion, at
                  any time and without liability or the need to give You prior
                  notice or to refund any unused services to:
                </li>
                <li>
                  suspend or revoke Your registration and Your right to access
                  and/or use the Website;
                </li>
                <li>
                  make use of any operational, technological, legal or other
                  means available to enforce the Terms.
                </li>
              </ul>
            </li>
            <div>
              We will try (but We are not obliged to) notify You if Your access
              to the Website and/or Your profile is to be or has been suspended
              or terminated. Where You have acted contrary to Our Terms, We will
              keep a record of it and may block future attempts by You to use or
              access WL and its features.
            </div>
            <li>
              <span style={{ fontWeight: "bold" }}>By You:</span>
              <br />
              To terminate this Agreement for any reason whatsoever You may
              unsubscribe/ delete your Account or not use Our Website.
              <ul
                style={{
                  listStyleType: "lower-roman",
                  fontWeight: "normal",
                  marginLeft: "25px",
                }}
              >
                <li>
                  Upon the termination of this Agreement, You shall cease to use
                  the Website. In the event that Your rights to use the Website
                  are terminated, You may immediately lose access to any
                  information that may be on Our systems. We may also remove any
                  User Content (as defined below) that You post without cause
                  and without notice to You. Not accessing Our Website does not
                  make it mandatory for Us to not save Your information in
                  accordance to Our privacy policy for a period that We think is
                  necessary to either provide better services or for any other
                  legal obligation that exists or may arise from time to time.
                </li>
                <li>
                  Upon such termination of this agreement, any subscription fees
                  that is pending for the subscribed period shall be forfeited
                  and You shall not be refunded any of the subscription fees
                  that has already been paid for the usage of the Website.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Liability </u>
          <br />
          <ul
            style={{
              listStyleType: "decimal",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
          >
            <li>
              <span style={{ fontWeight: "bold" }}>
                Liability and Warranty Disclaimer.
              </span>
              <br />
              <ul
                style={{
                  listStyleType: "lower-roman",
                  fontWeight: "normal",
                  marginLeft: "25px",
                }}
              >
                <li>
                  You expressly acknowledge that use of the Website is at Your
                  sole risk and that the entire risk as to satisfactory quality,
                  performance, accuracy and effort is with You. Further, You
                  understand that any loss of data or damage to Your computer
                  system You experience using the Website is at Your sole risk.
                  To the maximum extent permitted by applicable law, the Mobile
                  Application/Website and any Services performed or provided by
                  WL are provided “as is” and “as available”, with all faults
                  and without warranty of any kind, and are subject to change at
                  any time without notice to You.
                </li>
                <li>
                  WL hereby disclaims all warranties and conditions with respect
                  to the Website and any services, either express, implied or
                  statutory, including, but not limited to, the implied
                  warranties and/or conditions of merchantability, of
                  satisfactory quality, of fitness for a particular purpose, of
                  accuracy, of quiet enjoyment, and of non-infringement of
                  third-party rights. WL does not warrant against interference
                  with Your enjoyment of the Website, which the functions
                  contained in, or services will meet Your requirements, that
                  the operation of the Website or services will be
                  uninterrupted, secure, or error-free, or that defects in the
                  Website or WL Services will be corrected. No oral or written
                  information or advice given by Us or its authorized
                  representatives shall create a warranty. Moreover, not all the
                  functionality and features set forth in this agreement, will
                  necessarily be available in the initial version (or any future
                  version) of the Website, and no mention of such functionality
                  or features herein should be construed as a promise to provide
                  such functionality or features in the Website or in any other
                  product offered by Us.
                </li>
                <li>
                  To the extent not prohibited by law, in no event shall WL be
                  liable for personal injury, or any incidental, special,
                  indirect, consequential, or punitive damages whatsoever,
                  including, without limitation, damages for loss of profits,
                  loss of data, business interruption, procurement of substitute
                  goods or substitute services, or any other commercial damages
                  or losses, arising out of or related to Your use or inability
                  to use the Website, however caused, regardless of the theory
                  of liability (contract, tort or otherwise) and even if We have
                  been advised of the possibility of such damages.
                </li>
                <li>
                  Notwithstanding anything to the contrary contained herein,
                  WL's liability to You for any cause whatsoever, and regardless
                  of the form of the action, will at all times be limited to the
                  amount paid, if any, by You to WL, for any specific WL paid
                  package, and no further.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Indemnity </u>
          <br />
          <div>
            The User agrees to indemnify the Website and Company from all
            damages, liabilities, costs, charges and expenses, including
            reasonable attorneys’ fees, that the Website, Company, their
            affiliates, employees, and authorized representatives may incur as a
            result of either: (i) the User’s breach of this Agreement; or (ii)
            material entered into the Website with the use of the User’s screen
            name or password.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>General </u>
          <br />
          <div>
            19.1 In the event you file a false complaint against another User on
            our Website and consequently we have suspended that User profile,
            based on your complaint, then we reserve our right to initiate
            appropriate legal (Civil/Criminal) action against you and claim any
            and all costs expenses from you, for such
            irresponsible/misrepresentation/illegal/unlawful action. We also
            reserve our right to suspend your profile and forfeit any and all
            amounts paid by you for the WL services.
            <br />
            19.2 Notwithstanding anything contained herein, WL reserves the
            absolute right to delete, in any manner as it deems fit, any content
            of any User / Recruiter info placed on our website (once such
            instance come to WL notice) in order to ensure, that proper consent
            has been obtained by you , prima facie accuracy and the prevailing
            laws in force for the time being, especially those relating to
            providing any obscene, libelous, blasphemous, slanderous, defamatory
            or invasive of another person’s (deceased or alive) right of privacy
            or publicity, or that may reasonably be deemed to be harmful,
            vulgar, pornographic, abusive, harassing, threatening, hateful,
            objectionable with respect to race, religion, creed, nationality,
            gender or otherwise unfit for reproduction; or suggests or
            encourages unfair or illegal /indecent, unlawful activity. WL also
            reserves the right to block/ delete / suspend the User / Recruiter
            info which might be offensive, illegal or that might violate the
            rights, harm or threaten the safety of our office bearers/employees
            (including undue communication with any employee) and/or other
            registered prospects or using our WL Website as a means of
            communication while sending messages to other Users / Recruiters
            regarding their profile identity in other websites.
            <br />
            19.3 In case of conflict between the terms and condition of Website
            and terms and conditions of any other website including other
            Websites of the Company, the terms and condition of WL Website shall
            prevail for the service provided through this Website.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Changes to the Terms </u>
          <br />
          <ol
            style={{
              listStyleType: "decimal",
              fontWeight: "normal",
              marginLeft: "25px",
            }}
            start={1}
          >
            <li>
              Company may change these Terms & Conditions at any time without
              notice. Changes will be posted on the Website under “Terms &
              Conditions”.
            </li>
            <li>
              Your use of the Website after any changes have been posted will
              constitute Your agreement to the modified Terms & Conditions and
              all of the changes. Therefore, You should read these Terms &
              Conditions from time to time for changes.
            </li>
          </ol>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Jurisdiction and Applicable Law </u>
          <br />
          <div>
            The registration in WL and all of WL services are deemed to have
            been entered into within the territorial Jurisdiction of Chennai,
            Tamil Nadu, India.
          </div>
          <div>
            WL Users unconditionally agree that all such disputes and / or
            differences if any shall be governed by the Laws of India and
            submitting to the exclusive Jurisdiction of appropriate court of law
            in Chennai, Tamil Nadu, India.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>Arbitration of Disputes</u>
          <br />
          <div>
            The Parties shall discuss among themselves and resolve any disputes
            informally. We are interested in attempting to resolve any Disputes
            by amicable and informal means, and We encourage You to contact Us
            before resorting to arbitration. If the dispute is not resolved
            within 30 (thirty) days from the date of such dispute, then such
            dispute may only be resolved through an individual arbitration
            conducted in accordance with the provisions of The Arbitration and
            conciliation Act, 1996, by sole arbitrator. The seat of Arbitration
            shall be Chennai, Tamil Nadu, India. We shall appoint the sole
            arbitrator. The arbitration proceedings shall be conducted in the
            English language.
          </div>
          <div>
            However, Disputes relating to infringement of Our intellectual
            property rights, which We may elect to have resolved by means other
            than arbitration.
          </div>
          <div>
            Any proceeding to enforce this arbitration agreement may be brought
            in Courts of Chennai, Tamil Nadu India.
          </div>
        </li>
        <li>
          <u style={{ fontWeight: "bold" }}>About Us </u>
          <br />
          <div>Weddingloan.com is a Website with, owned by matrimony.com.</div>
          <div>
            These Terms and Conditions were last updated on 23 AUG 2024{" "}
          </div>
          {/* <div>
            Any proceeding to enforce this arbitration agreement may be brought
            in Courts of Chennai, Tamil Nadu India.
          </div> */}
        </li>
      </ol>
    </Container>
  );
};

export default TermsAndConditons;
