import { gql } from '@apollo/client';

export const SSO_VERIFICATION = gql`
	mutation SsoVerification($input: SsoInput) {
		ssoVerification(input: $input) {
			SsoPayloadErrors {
				message
			}
			message
			status
			token
			user {
				id
				mobileNumber
				userstatusId
			}
		}
	}
`;
