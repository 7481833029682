import React, { useEffect } from "react";
import editIcon from "../../assets/editIcon.svg";
import otpIcon from "../../assets/otpIcon.gif";
import { OutlinedInput, Box } from "@mui/material";

interface Props {
  mobileNumber: string;
  setForm: React.Dispatch<React.SetStateAction<number>>;
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
  otp: string[];
  data: any;
  registerMobileNumber: () => void;
}

const otpInputStyle = {
  display: "flex",
  gap: "35px",
  marginTop: "35px",
};

const OTPModal = ({
  mobileNumber,
  setForm,
  setOtp,
  otp,
  data,
  registerMobileNumber,
}: Props) => {
  const [countDown, setCountDown] = React.useState(59);
  const [error, setError] = React.useState<boolean>(false);

  const seconds = String(countDown % 60).padStart(2, "0");
  useEffect(()=>{
    if(data?.verifyOtp?.verifyOtpErrors[0]?.message){
      setError(true)
    }else{
      setError(false)
    }
  },[data])
  
  const resendOTP = () => {
    setError(false)
    setCountDown(59);
    registerMobileNumber();
  };

  useEffect(() => {
    let interval: any;
    const handleCountDownTimer = () => {
      if (countDown > 0) {
        interval = setTimeout(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearTimeout(interval);
      }
    };
    handleCountDownTimer();
    return () => {
      clearTimeout(interval);
    };
  }, [countDown]);

  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    currentIndex: number
  ) => {
    const newOtpTemp = [...otp];
    const value = e.target.value;
    // Allow only one value for input
    if (/^\d*$/.test(value)) {
      newOtpTemp[currentIndex] = value.substring(value.length - 1);
      setOtp(newOtpTemp);

      // Get the next input field using it's name
      const nextfield = document.querySelector(
        `input[name=otpInput-${currentIndex + 1}]`
      );

      //Moves the focus to the next input field
      if (newOtpTemp[currentIndex] !== "" && currentIndex !== otp.length - 1) {
        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLFormElement>,
    index: number
  ) => {
    setError(false);
    const key = e.key;
    if (!/^\d$/.test(e.key) && !["Backspace","Delete","Tab","ArrowLeft","ArrowRight","ArrowUp","ArrowDown","Enter"].includes(e.key)) {
      e.preventDefault();
    }
    //Moves the focus to the previous input field
    if (key === "Backspace" && index !== 0 && !otp[index]) {
      const prevfield = document.querySelector(
        `input[name=otpInput-${index - 1}]`
      );
      if (prevfield !== null) {
        (prevfield as HTMLElement).focus();
      }
    }
  };

  return (
    <>
      <img src={otpIcon} alt="otp-Icon" className="icon-gif" />
      <div className="fs-24 heading">Enter OTP</div>
      <span className="fs-14">
        We’ve sent 4 digit code to{" "}
        <span className="fw-600">{mobileNumber}</span>
        <span
          className="app-text-color fs-14 cursor-pointer fw-500"
          onClick={() => setForm((prev: number) => prev - 1)}
        >
          {" "}
          Edit number <img src={editIcon} alt="editIcon" />{" "}
        </span>
      </span>
      <Box sx={otpInputStyle}>
        {(otp || []).map((item: string, index: number) => (
          <OutlinedInput
            name={`otpInput-${index}`}
            className="otp-input-field"
            type="number"
            value={item}
            key={index}
            onKeyDown={(e:any) => handleKeyDown(e, index)}
            // onKeyDown={(e:any) => handleKeyDown(e, index)}
            error={otp.join("") !== "" && data?.verifyOtp?.message === null && error}
            autoFocus={index === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOtpInput(e, index)
            }
          />
        ))}

        {/* <Form.Group controlId="formFile" className="d-flex gap-4 mt-35">
                {(otp || []).map((item: string, index: number) => (
                    <Form.Control
                        name={`otpInput-${index}`}
                        className="otp-input-field"
                        type="number"
                        value={item}
                        maxLength={1}
                        key={index}
                        pattern="!/^\d{10}$/"
                        onKeyDown={(e) => ["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}
                        onKeyUp={(e: any) => handleKeyDown(e, index)}
                        isInvalid={otp.join("") !== "" && data?.verifyOtp?.message === null}
                        autoFocus={index === 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOtpInput(e, index)} 
                    />
                ))}
            </Form.Group> */}
      </Box>
      {error && (
        <label className="error-mess">
          {data?.verifyOtp?.verifyOtpErrors[0]?.message}
        </label>
      )}
      <Box sx={{ mt: 2 }}>
        {countDown !== 0 ? (
          <div className="fs-14">
            Didn’t receive OTP?{" "}
            <span className="app-text-color">
              Resend in {"00"}:{seconds}
            </span>
          </div>
        ) : (
          <span
            className="text-decoration-underline cursor-pointer app-text-color fs-14"
            onClick={() => resendOTP()}
          >
            Resend OTP
          </span>
        )}
      </Box>
    </>
  );
};

export default OTPModal;
