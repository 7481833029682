import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import { getToken } from './utils';
import { Helmet } from 'react-helmet';
import publicRoutes from './routes/PublicRoutes';
import privateRoutes from './routes/PrivateRoutes';

interface RouteProps {
  element: React.ComponentType<any>;
  [key: string]: any;
}


interface MetaConfig {
  title: string;
  description: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  canonical?: string;
  noindex?: boolean;
}

interface RouteConfig {
  path: string;
  element: React.ReactElement;
  meta: MetaConfig;
}

const defaultMeta: MetaConfig = {
  title: 'Wedding Loan | Apply for Marriage Loan starting at 10.99%',
  description: 'Make your dream wedding a reality with WeddingLoan.com! Explore personalized financing options for your marriage expenses with a dedicated Relationship Manager',
  keywords: `Wedding loan,Marriage loan,Wedding finance,Personal loan for weddings,Personal loan for marriage,Affordable wedding financing,Wedding budget assistance,Loans for marriage costs,Wedding expenses,Wedding planning loan,Financial help for weddings,Best wedding loan options`,
  ogTitle: 'Wedding Loan | Apply for Marriage Loan starting at 10.99%',
  ogDescription: 'Make your dream wedding a reality with WeddingLoan.com! Explore personalized financing options for your marriage expenses with a dedicated Relationship Manager',
};



const isAuthenticated = () => {
  return getToken();
};

const ProtectedRoute: React.FC<RouteProps> = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? <Element {...rest} /> : <Navigate to="/login" replace />;
};

const PublicRoute: React.FC<RouteProps> = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? <Navigate to="/loans" replace /> : <Element {...rest} />;
};



const EnhancedPublicRoute: React.FC<{ config: RouteConfig }> = ({ config }) => {
  const finalMeta = { ...defaultMeta, ...config.meta };
  
  return (
    <>
      <Helmet>
        <title>{finalMeta.title}</title>
        <meta name="description" content={finalMeta.description}  />
        {finalMeta.keywords && <meta name="keywords" content={finalMeta.keywords}  />}
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={finalMeta.ogTitle || finalMeta.title}  />
        <meta property="og:description" content={finalMeta.ogDescription || finalMeta.description}  />
        {finalMeta.ogImage && <meta property="og:image" content={finalMeta.ogImage} />}
        
        {/* Additional Meta Tags */}
        {finalMeta.canonical && <link rel="canonical" href={finalMeta.canonical}  />}
        {finalMeta.noindex && <meta name="robots" content="noindex"  />}
      </Helmet>
      <PublicRoute element={() => config.element} />
    </>
  );
};

const EnhancedPrivateRoute: React.FC<{ config: RouteConfig }> = ({ config }) => {
  const finalMeta = { ...defaultMeta, ...config.meta };
  
  return (
    <>
      <Helmet>
        <title>{finalMeta.title}</title>
        <meta name="description" content={finalMeta.description} />
        {finalMeta.keywords && <meta name="keywords" content={finalMeta.keywords}  />}
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={finalMeta.ogTitle || finalMeta.title}  />
        <meta property="og:description" content={finalMeta.ogDescription || finalMeta.description} />
        {finalMeta.ogImage && <meta property="og:image" content={finalMeta.ogImage} />}
        
        {/* Additional Meta Tags */}
        {finalMeta.canonical && <link rel="canonical" href={finalMeta.canonical} />}
        {finalMeta.noindex && <meta name="robots" content="noindex"  />}
      </Helmet>
      <ProtectedRoute element={() => config.element} />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        {/* Default meta tags for the entire application */}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        
        {/* Default OpenGraph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Wedding Loan" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@weddingloan" />
        
        {/* Favicon and other link tags */}
        <link rel="icon" href="/favicon_wl.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        
        {/* Default title and description */}
        <title>{defaultMeta.title}</title>
        <meta name="description" content={defaultMeta.description} />
      </Helmet>

      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<EnhancedPublicRoute config={route} />}
          />
        ))}
        
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<EnhancedPrivateRoute config={route} />}
          />
        ))}
        
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;