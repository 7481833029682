import { BorderRight } from "@mui/icons-material";
import {
    Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#156082",
    color: theme.palette.common.white,
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    borderLeft: "1px solid black !important",
  },
}));

const ProductDetails = () => {
  return (
    <Box mx={4} mt={4} px={2}>
      <Box sx={{textDecoration:'underline'}}>Product Details</Box>
      <Box mt={2}>
        <TableContainer>
          {/* <TableStyle aria-label="simple table"> */}
          <TableHead>
            <TableRow>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell>Interest Rate (%)</StyledTableCell>
              <StyledTableCell>Loan Amount (₹)</StyledTableCell>
              <StyledTableCell sx={{borderRight:"1px solid black"}}>Tenure</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ }}
            >
              <StyledTableCell>IDFC First Bank 
                FIRSTmoney
              </StyledTableCell>
              <StyledTableCell>Starts from 10.99%</StyledTableCell>
              <StyledTableCell>
                <Box>Upto 10 Lakh</Box>
              </StyledTableCell>
              <StyledTableCell sx={{borderRight:"1px solid black"}}>
                <Box>Upto 60 Months</Box>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{ }}
            >
              <StyledTableCell>L&T Finance</StyledTableCell>
              <StyledTableCell>Starts from 12%</StyledTableCell>
              <StyledTableCell>
                <Box>Upto 15 Lakh</Box>
              </StyledTableCell>
              <StyledTableCell sx={{borderRight:"1px solid black"}}>
                <Box>Upto 48 Months</Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
          {/* </TableStyle> */}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProductDetails;
