import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useMutation } from '@apollo/client';
import { SSO_VERIFICATION } from '../../query/Register/ssoQuery';
import { useNavigate } from 'react-router-dom';
import { setToken, setUserDetails } from '../../utils';
import { StatusIds } from '../../constants';

interface SSOLoaderProps {
    message?: string;
}

const AutoLogin: React.FC<SSOLoaderProps> = ({
    message = 'Verifying your credentials...'
}) => {


    const [ssoVerification, { data: ssoResponse }] = useMutation(SSO_VERIFICATION, {});

    const navigate = useNavigate();

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const hash = params.get('hash');
        const source = params.get('source');

        if (hash) {
            ssoVerification({
                variables: {
                    input: {
                        hash,
                        source
                    }
                }
            })
            if (ssoResponse) {

                const userObj = {
                    id: ssoResponse?.ssoVerification?.user?.id,
                    userStatusId: ssoResponse?.ssoVerification?.user?.userstatusId
                }
                setToken(ssoResponse?.ssoVerification?.token);
                setUserDetails(userObj);
                if (+ssoResponse?.ssoVerification?.user?.userstatusId === StatusIds.userStatus.newUser) {
                    navigate("/basic-details");
                } else if (+ssoResponse?.ssoVerification?.user?.userstatusId >= StatusIds.userStatus.basicDetailsStatus && +ssoResponse?.ssoVerification?.user?.userstatusId <= StatusIds.userStatus.permanentAddressStatus) {
                    navigate("/location-details");
                } else if (+ssoResponse?.ssoVerification?.user?.userstatusId === StatusIds.userStatus.permanentAddressStatus) {
                    navigate("/employment-details");
                }

            }
        }
    }, [ssoResponse])

    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.default,
                zIndex: theme.zIndex.modal + 1,
                gap: 3
            }}
        >
            <CircularProgress
                size={60}
                thickness={4}
                sx={{
                    color: theme.palette.primary.main,
                    '@keyframes pulse': {
                        '0%': {
                            opacity: 0.7,
                            transform: 'scale(0.95)',
                        },
                        '50%': {
                            opacity: 1,
                            transform: 'scale(1.05)',
                        },
                        '100%': {
                            opacity: 0.7,
                            transform: 'scale(0.95)',
                        },
                    },
                    animation: 'pulse 2s ease-in-out infinite',
                }}
            />
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.text.primary,
                    textAlign: 'center',
                    maxWidth: '80%',
                    '@keyframes fadeInOut': {
                        '0%': { opacity: 0.5 },
                        '50%': { opacity: 1 },
                        '100%': { opacity: 0.5 },
                    },
                    animation: 'fadeInOut 2s ease-in-out infinite',
                }}
            >
                {message}
            </Typography>
        </Box>
    );
};

export default AutoLogin;